/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/global.scss');
require("@fortawesome/fontawesome-free/js/all.js");

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $  = require( 'jquery' );
window.jQuery = $;
window.$ = $;

require("./jqExtensions.js");
require('bootstrap');
require('jquery-ui/ui/widgets/autocomplete.js');

import dt from 'datatables.net'
window.dt = dt;