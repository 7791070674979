$.fn.brothers = function(param1){
    return $(this).parent().children(param1);
};
$.fn.labelForContains = function(valeur){
    if(this.length > 0){
        return $(this).brothers("label:contains('"+valeur+"')").brothers(this[0].tagName+":"+this[0].type);
    } else {
        return $(this);
    }
};
Array.prototype.diff = function(a) {
    return this.filter(function(i) {return a.indexOf(i) < 0;});
};
